import { createError } from '../errors.js';
export const AVAILABLE_PLUGIN_HOOKS = ['beforeInsert', 'afterInsert', 'beforeRemove', 'afterRemove', 'beforeUpdate', 'afterUpdate', 'beforeSearch', 'afterSearch', 'beforeInsertMultiple', 'afterInsertMultiple', 'beforeRemoveMultiple', 'afterRemoveMultiple', 'beforeUpdateMultiple', 'afterUpdateMultiple', 'beforeLoad', 'afterLoad', 'afterCreate'];
export async function getAllPluginsByHook(orama, hook) {
  var _orama_plugins;
  const pluginsToRun = [];
  const pluginsLength = (_orama_plugins = orama.plugins) === null || _orama_plugins === void 0 ? void 0 : _orama_plugins.length;
  if (!pluginsLength) {
    return pluginsToRun;
  }
  for (let i = 0; i < pluginsLength; i++) {
    try {
      const plugin = await orama.plugins[i];
      if (typeof plugin[hook] === 'function') {
        pluginsToRun.push(plugin[hook]);
      }
    } catch (error) {
      console.error('Caught error in getAllPluginsByHook:', error);
      throw createError('PLUGIN_CRASHED');
    }
  }
  return pluginsToRun;
}

