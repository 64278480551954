<div class="ng-doc-search-dialog-container">
  <ng-doc-input-wrapper>
    <ng-doc-icon icon="search" ngDocInputWrapperLeft></ng-doc-icon>
    <input
      ngDocInputString
      ngDocAutofocus
      [selectAll]="true"
      placeholder="Find or ask anything..."
      [(ngModel)]="searchTerm"
      (ngModelChange)="search($event)"
      #inputElement />
  </ng-doc-input-wrapper>

  <div
    class="ng-doc-search-result-content"
    *ngIf="data.searchResults | async as state"
    #resultContent>
    <ng-doc-data-list
      [items]="state?.result ?? []"
      [itemContent]="itemContent"
      [emptyContent]="emptyContent">
      <ng-template let-item #itemContent>
        <a
          class="ng-doc-search-option"
          [routerLink]="item.index.route"
          [fragment]="item.index.fragment"
          (click)="overlayRef.close()">
          <div class="ng-doc-search-option-header">
            <ng-doc-tag size="small" [attr.data-ng-doc-page-type]="item.index.pageType">
              {{ item.index.pageType }}
            </ng-doc-tag>
            <span class="ng-doc-search-option-breadcrumbs" ng-doc-text>
              <ng-container *ngFor="let breadcrum of item.index.breadcrumbs; let i = index">
                <span>{{ breadcrum }}</span>
                <span
                  class="ng-doc-search-option-breadcrumb-divider"
                  *ngIf="i !== item.index.breadcrumbs.length - 1"
                  ><b>•</b></span
                >
              </ng-container>
            </span>
          </div>
          <h5
            class="ng-doc-search-section-title"
            ng-doc-text
            [innerHTML]="
              (item.index.section
                | ngDocHighlighterPipe: (getPositions | execute: 'section' : item)
                | ngDocSanitizeHtml) || item.index.title
            "></h5>
          <div
            *ngIf="item.index.content"
            class="ng-doc-search-content"
            [innerHTML]="
              item.index.content
                | ngDocHighlighterPipe: (getPositions | execute: 'content' : item)
                | ngDocSanitizeHtml
            "
            ng-doc-text></div>
        </a>
      </ng-template>
      <ng-template #emptyContent>
        <ng-container *ngIf="!state?.pending">
          <div class="ng-doc-search-empty">
            <ng-container *ngIf="searchTerm.length">Nothing found</ng-container>
            <ng-container *ngIf="!searchTerm.length">Please enter your search query </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="state?.pending">
          <div class="ng-doc-search-progress">
            <ng-doc-spinner size="small"></ng-doc-spinner>
            Searching...
          </div>
        </ng-container>
      </ng-template>
    </ng-doc-data-list>
  </div>
</div>
