<a class="ng-doc-sidebar-link" [routerLink]="[item().route]" routerLinkActive="active">
  <ng-doc-dot></ng-doc-dot>
  <span ng-doc-text [absoluteContent]="true">
    {{ item().title }}
    @for (status of statuses(); track status) {
      <ng-doc-tag size="small" [color]="status.type || 'success'" mod="light">{{
        status.text
      }}</ng-doc-tag>
    }
  </span>
</a>
