<div class="ng-doc-sidenav-wrapper">
  <div class="ng-doc-sidenav">
    <ng-container *polymorpheusOutlet="sidebar"></ng-container>
  </div>
  <div class="ng-doc-sidenav-content">
    @if (opened && hasBackdrop) {
      <div class="ng-doc-backdrop" @backdropFade (click)="closeEvent.emit()"></div>
    }

    <ng-content></ng-content>
  </div>
</div>
