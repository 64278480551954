<ng-doc-list>
	<ng-container *ngIf="items && items.length; else emptyTemplate">
		<ng-doc-option
			*ngFor="let item of items; trackBy: trackByFn"
			[value]="defineValueFn(item)"
			[disabled]="itemDisabledFn(item)">
			<ng-container *polymorpheusOutlet="itemContent as text; context: getContext(item)">
				{{ text }}
			</ng-container>
		</ng-doc-option>
	</ng-container>

	<ng-template #emptyTemplate>
		<div class="ng-doc-empty-message" ng-doc-text>
			<ng-container *ngIf="emptyContent">
				<ng-container *polymorpheusOutlet="emptyContent as text"> {{ text }} </ng-container>
			</ng-container>
		</div>
	</ng-template>
</ng-doc-list>
