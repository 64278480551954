<div class="ng-doc-tabs-wrapper" ngDocSelectionHost>
	<ng-doc-selection></ng-doc-selection>
	<div
		class="ng-doc-tab"
		[class.selected]="tab === selectedTab"
		*ngFor="let tab of tabs"
		(click)="selectTab(tab)"
		[ngDocSelectionOrigin]="tab === selectedTab"
		#headerTab>
		<div class="ng-doc-tab-text">
			<ng-container *polymorpheusOutlet="tab.label; context: {}">{{ tab.label }}</ng-container>
		</div>
	</div>
</div>

<div class="ng-doc-body-wrapper">
	<ng-doc-smooth-resize [trigger]="selectedTab?.content ?? ''">
		<div *polymorpheusOutlet="selectedTab?.content ?? '' as text; context: {}" @tabFadeAnimation>
			{{ text }}
		</div>
	</ng-doc-smooth-resize>
</div>
