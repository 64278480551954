<button ng-doc-button-icon size="large" (click)="toggleTheme()" [ngDocTooltip]="tooltipContent">
  <ng-template #tooltipContent>
    <ng-doc-smooth-resize [trigger]="currentTheme.name">
      "{{ currentTheme.name }}" theme
    </ng-doc-smooth-resize>
  </ng-template>

  @if (currentTheme === themes[0]) {
    <div class="letter">A</div>
  } @else if (currentTheme === themes[1]) {
    <ng-doc-icon icon="sun" size="24"></ng-doc-icon>
  } @else {
    <ng-doc-icon icon="moon" size="24"></ng-doc-icon>
  }
</button>
