<ng-container>
  <div class="ng-doc-root-wrapper">
    <div class="ng-doc-header">
      <ng-content select="ng-doc-navbar"></ng-content>
      <ng-content select="[ngDocCustomNavbar]"></ng-content>
    </div>
    <main>
      <ng-doc-sidenav
        [sidebar]="sidebar ? sidebarContent : null"
        [opened]="(sidebarService.isExpanded() | async) ?? true"
        [hasBackdrop]="sidebarService.isMobile"
        (closeEvent)="sidebarService.hide()">
        <ng-template #sidebarContent>
          <ng-content select="ng-doc-sidebar"></ng-content>
          <ng-content select="[ngDocCustomSidebar]"></ng-content>
        </ng-template>

        <ng-content></ng-content>
      </ng-doc-sidenav>
    </main>
    <footer *ngIf="footerContent">
      <ng-container *polymorpheusOutlet="footerContent as text"> {{ text }}</ng-container>
    </footer>
  </div>
</ng-container>
