<div class="navbar-container">
  <div class="ng-doc-navbar-left">
    <ng-content select="[ngDocNavbarLeft]"></ng-content>
  </div>
  <div class="ng-doc-navbar-right">
    @if (search) {
      <ng-doc-search></ng-doc-search>
    }
    <ng-content select="[ngDocNavbarRight]"></ng-content>
    <button class="ng-doc-menu" ng-doc-button-icon size="large" (click)="sidebarService.toggle()">
      <ng-doc-icon icon="menu" [size]="24"></ng-doc-icon>
    </button>
  </div>
</div>
